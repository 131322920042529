import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verifier',
  templateUrl: './verifier.component.html',
  styleUrls: ['./verifier.component.css']
})
export class VerifierComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
