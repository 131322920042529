import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CourseService } from './course.service';
import { AlertService } from '../../common/alert.service';
import { Location } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {
  
  
  constructor(){

  }
  ngOnInit(){

  }
}
